<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a" @click="$router.back()"
            >评论管理</a
          >
          <i>></i>
          <a href="javascript:;" class="cur-a">评论列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                clearable
                placeholder="请输入班级名称"
                size="small"
              ></el-input>
            </div>
            <div title="讨论内容" class="searchboxItem ci-full">
              <span class="itemLabel">讨论内容:</span>
              <el-input
                v-model="content"
                clearable
                placeholder="请输入课讨论内容"
                size="small"
              ></el-input>
            </div>
            <div title="讨论时间" class="searchboxItem ci-full">
              <span class="itemLabel">讨论时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="discussTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </div>
          <div class="searchbox">
            <div title="举报次数" class="searchboxItem ci-full">
              <span class="itemLabel">举报次数:</span>
              <el-input
                v-model="reportNumStart"
                @keyup.native="reportNumStart = zF.oninput2(reportNumStart, 1)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="reportNumEnd"
                @keyup.native="reportNumEnd = zF.oninput2(reportNumEnd, 1)"
                placeholder="请输入"
                size="small"
                class="sjd"
              ></el-input>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="讨论内容"
                align="left"
                show-overflow-tooltip
                prop="content"
                min-width="200"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                width="100"
              />
              <el-table-column
                label="手机号码"
                align="center"
                show-overflow-tooltip
                prop="mobile"
                width="100"
              />
              <el-table-column
                label="班级名称"
                align="center"
                show-overflow-tooltip
                prop="projectName"
                min-width="200"
              />
              <el-table-column
                label="课程名称"
                align="center"
                show-overflow-tooltip
                prop="courseName"
                min-width="200"
              />
              <el-table-column
                label="创建时间"
                align="center"
                show-overflow-tooltip
                prop="createTime"
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="举报次数"
                align="center"
                show-overflow-tooltip
                prop="reportNum"
                min-width="100"
              />
              <el-table-column
                label="操作"
                align="center"
                width="160px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="seeReportInfo(scope.row)"
                    >查看举报人</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="deleteInfo(scope.row)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog title="被举报人" :visible.sync="dialogFormVisible" width="35%">
      <el-table
        :data="reportInfoData"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          min-width="50"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="姓名"
          align="center"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="reportTime"
          label="日期"
          align="center"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.reportTime | moment }}
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="bgc-bv"
          size="small"
          @click="dialogFormVisible = false"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/commentManagementList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      projectName: "", // 班级名称
      content: "", // 评论内容
      discussTime: "", // 讨论时间
      reportNumStart: "",
      reportNumEnd: "",
      reportInfoData: [], // 查看举报人的列表数据
      dialogFormVisible: false, // 查看举报人的弹窗
    };
  },
  watch: {},
  created() {},
  computed: {},
  mounted() {},
  methods: {
    // 初始化获取评论列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.content) {
        params.content = this.content;
      }
      if (this.discussTime && this.discussTime.length) {
        params.discussTimeStart = this.discussTime[0];
        params.discussTimeEnd = this.discussTime[1];
      }
      if (
        (this.reportNumStart && !this.reportNumEnd) ||
        (!this.reportNumStart && this.reportNumEnd)
      ) {
        this.$message.warning("请补全举报次数区间");
        return false;
      }
      if (this.reportNumStart && this.reportNumEnd) {
        if (Number(this.reportNumStart) > Number(this.reportNumEnd)) {
          this.$message.warning("起始举报次数不能大于截止举报次数");
          return false;
        } else {
          params.reportNumStart = this.reportNumStart;
          params.reportNumEnd = this.reportNumEnd;
        }
      }
      this.doFetch({
        url: "/biz/course/discuss/operate/discuss",
        params,
        pageNum,
      });
    },
    // 查看举报人
    seeReportInfo(row) {
      this.dialogFormVisible = true;
      this.getreportDiscuss(row.discussId);
    },
    // 删除
    deleteInfo(row) {
      let _this = this;
      _this.$confirm('此操作将删除该条评论(包括含有下级回复的评论), 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.$post("/biz/course/discuss/remove", {
        discussId:row.discussId,
      })
        .then((res) => {
          _this.getData(-1);
          _this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(err => {
          _this.$message({
            type: "warning",
            message: err,
          });
        });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
     
    },
    getreportDiscuss(discussId) {
      this.$post("/biz/course/discuss/operate/report", { discussId }).then(
        (ret) => {
          this.reportInfoData = ret.data;
        }
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
</style>